import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

import { logActionPromiseParamSchema } from '../log-action/logActionPromise';

const listAuditLogsPromiseParamSchema = z.object({
  data: z.array(
    logActionPromiseParamSchema.extend({
      id: z.string(),
      created_at: z.string(),
      username: z.string(),
    })
  ),
  error: z.string().optional().nullable(),
});

export type ListAuditLogs = z.infer<typeof listAuditLogsPromiseParamSchema>['data'];

const LOGS_PER_PAGE = 20;

export const paginateLogs = (logs: ListAuditLogs, logPerPage?: number) => {
  const nPerPage = logPerPage || LOGS_PER_PAGE;

  const numberOfPages = Math.ceil(logs.length / nPerPage);

  const paginated = Array.from(Array(numberOfPages).keys()).map((i) => {
    const start = i * nPerPage;
    return logs.slice(start, start + nPerPage);
  });

  return {
    logs: paginated,
    totalPages: numberOfPages,
    raw: logs,
  };
};

export type PaginatedLogs = ReturnType<typeof paginateLogs>;

export interface ListAuditLogsPromiseParams {
  log_type?: Array<string>;
  search?: string;
}

export const listAuditLogsPromise = async (params: ListAuditLogsPromiseParams) => {
  const res = await ky
    .post(`${ENV.FRONTEND_API_URL}/list_audit_logs`, {
      body: JSON.stringify(params),
    })
    .json();

  const result = listAuditLogsPromiseParamSchema.safeParse(res);

  if (!result.success) {
    console.error(result.error);
    return {
      logs: [],
      totalPages: 0,
      raw: [],
    };
  }

  return paginateLogs(result.data?.data ?? []);
};

export const listRetryUserReportLogsPromise = async () => {
  const res = await ky.get(`${ENV.FRONTEND_API_URL}/list_retry_report_logs`).json();

  const result = listAuditLogsPromiseParamSchema.safeParse(res);

  if (!result.success) {
    console.error(result.error);
    return {
      logs: [],
      totalPages: 0,
      raw: [],
    };
  }

  return paginateLogs(result.data?.data ?? [], 5);
};
