import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import { LiveblocksProvider } from '@liveblocks/react/suspense';
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { useReducedMotion, Globals } from '@react-spring/web';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NotificationDrawer } from 'src/components/atoms/LiveBlockNotification/NotificationDrawer';
import { initI18n, getTheme, MUI_THEME, QUERY_CLIENT } from 'src/constants';
import { useGlobalStore, useEnableDarkModeOnDocumentBody } from 'src/hooks';
import { SNACKBAR_PROVIDE_PROPS } from './snackbar-provider-options';
import { USERS } from './users';
import { getRoomDetailsByRoomId } from '../constants/liveblock-room-id-prefix';
import '@liveblocks/react-ui/styles.css';

/** Switch these to a controller */
const LANGUAGE = 'en';
export interface AppProviderProps {
  children: React.ReactNode;
}

const LIV_BLOCK_ENDPOINT =
  import.meta.env.MODE === 'development'
    ? 'http://localhost:9999/.netlify/functions/live-block-auth'
    : '/.netlify/functions/live-block-auth';

export const AppProvider: React.FunctionComponent<AppProviderProps> = ({ children }) => {
  const isDarkTheme = useGlobalStore((state) => state.darkMode);
  const currentI18n = React.useMemo(() => initI18n(LANGUAGE), []);
  const currentTheme = React.useMemo(() => getTheme(isDarkTheme), [isDarkTheme]);
  const prefersReducedMotion = useReducedMotion();
  useEnableDarkModeOnDocumentBody();

  /** Disable react-spring animation for users that have reduce motion accessibility setting on */
  React.useEffect(() => {
    Globals.assign({
      skipAnimation: Boolean(prefersReducedMotion),
    });
  }, [prefersReducedMotion]);

  return (
    <>
      <CssBaseline />
      <I18nextProvider i18n={currentI18n}>
        <MuiThemeProvider theme={MUI_THEME}>
          <ThemeProvider theme={currentTheme}>
            <StyledEngineProvider injectFirst>
              <QueryClientProvider client={QUERY_CLIENT}>
                <SnackbarProvider {...SNACKBAR_PROVIDE_PROPS}>
                  <LiveblocksProvider
                    authEndpoint={LIV_BLOCK_ENDPOINT}
                    resolveUsers={({ userIds }) => {
                      if (!userIds) return USERS;

                      return userIds.map((el) => USERS.find((ul) => ul.id === el) || { name: el });
                    }}
                    resolveMentionSuggestions={({ text }) => {
                      if (!text) return USERS.map((el) => el.id);
                      return USERS.filter((el) => el.email.includes(text)).map((el) => el.id);
                    }}
                    resolveRoomsInfo={({ roomIds }) => {
                      return roomIds.map((el) => getRoomDetailsByRoomId(el)).filter(Boolean);
                    }}
                  >
                    {children}
                    <NotificationDrawer />
                  </LiveblocksProvider>
                </SnackbarProvider>
                <ReactQueryDevtools buttonPosition="bottom-left" />
              </QueryClientProvider>
            </StyledEngineProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </I18nextProvider>
    </>
  );
};
