import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const CREATE_ORG_PARAMS_SCHEMA = z.object({
  uuid: z.string(),
  org_name: z.string(),
  org_id: z.string(),
  logo_url: z.string(),
});

const CREATE_ORG_RESPONSE_SCHEMA = z.object({
  message: z.string(),
});

export type CreateOrgParams = z.infer<typeof CREATE_ORG_PARAMS_SCHEMA>;

export const createOrgPromise = async (params: CreateOrgParams) => {
  const parsedParams = CREATE_ORG_PARAMS_SCHEMA.safeParse(params);

  if (!parsedParams.success) {
    console.error('Invalid params', parsedParams.error);
    return false;
  }

  const response = await ky.post(`${ENV.FRONTEND_API_URL}/create_org`, { body: JSON.stringify(params) }).json();

  const parsedResponse = CREATE_ORG_RESPONSE_SCHEMA.safeParse(response);

  if (!parsedResponse.success) {
    console.error('Invalid response', parsedResponse.error);
    return false;
  }

  return parsedResponse?.data?.message?.includes('created');
};
