import { InboxNotification, InboxNotificationList } from '@liveblocks/react-ui';
import { useInboxNotifications } from '@liveblocks/react/suspense';
import { StyledLiveBlockNotification } from './LiveBlockNotification.styled';

export const LiveBlockNotification = () => {
  const { inboxNotifications } = useInboxNotifications();

  return (
    <StyledLiveBlockNotification>
      <InboxNotificationList>
        {inboxNotifications?.length
          ? inboxNotifications.map((inboxNotification) => (
              <InboxNotification key={inboxNotification.id} inboxNotification={inboxNotification} showActions={true} />
            ))
          : null}
      </InboxNotificationList>
    </StyledLiveBlockNotification>
  );
};
