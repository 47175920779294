import * as React from 'react';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useMarkAllInboxNotificationsAsRead } from '@liveblocks/react/suspense';
import { LiveBlockNotification } from './LiveBlockNotification';
import { P18 } from '../Typography';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { CheckIcon, CloseIcon } from '../../../assets/icons';
import { useNotificationOpenStateStore } from './notification-store';

export const NotificationDrawer = () => {
  const theme = useTheme();

  const markAllInboxNotificationsAsRead = useMarkAllInboxNotificationsAsRead();

  const isOpen = useNotificationOpenStateStore((state) => state.open);

  const handleCloseDrawer = () => {
    useNotificationOpenStateStore.setState({
      open: false,
    });
  };
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: { width: 500, height: '100%', bgcolor: theme.colors.appBackground, p: '0 8px 8px 8px' },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        mb={1}
        pb={1}
        borderBottom={`1px solid ${theme.colors.borderColor}`}
        position="sticky"
        top="0"
        pt={1}
        zIndex={1}
        bgcolor={theme.colors.appBackground}
      >
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon fill={theme.colors.grey1} />
        </IconButton>{' '}
        <P18 $fontWeight={700} $margin="0 auto 0 0">
          Notifications
        </P18>
        <Button
          color="secondary"
          size="small"
          onClick={markAllInboxNotificationsAsRead}
          startIcon={<CheckIcon size={18} />}
        >
          Mark all as read
        </Button>
      </Box>
      <React.Suspense>
        <LiveBlockNotification />
      </React.Suspense>
    </Drawer>
  );
};
