import { css } from 'styled-components';

export interface CustomTypographyProps {
  $color?: string;
  $margin?: string;
  $fontWeight?: number;
  $display?: string;
  $textAlign?: string;
  htmlFor?: string;
  $fontSize?: string;
  $capitalize?: boolean;
  $opacity?: number;
  $alignItems?: string;
  $padding?: string;
  $backgroundColor?: string;
}

export const CUSTOM_TYPOGRAPHY_CSS = css<CustomTypographyProps>`
  color: ${(props) => props.$color || props.theme.colors.textColor};
  margin: ${(props) => props.$margin};
  font-weight: ${(props) => props.$fontWeight};
  display: ${(props) => props.$display};
  text-align: ${(props) => props.$textAlign};
  font-size: ${(props) => props.$fontSize};
  text-transform: ${(props) => (props.$capitalize ? 'capitalize' : 'none')};
  opacity: ${(props) => props.$opacity};
  align-items: ${(props) => props.$alignItems};
  padding: ${(props) => props.$padding};
  cursor: ${(props) => (props.htmlFor ? 'pointer' : 'default')};
  background-color: ${(props) => props.$backgroundColor};
`;
