import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const MANAGEMENT_ORGS_SCHEMA = z.array(
  z.object({
    org_name: z.string(),
    org_id: z.string(),
    logo_url: z.string(),
    uuid: z.string(),
  })
);

export type ManagementOrgsSchema = z.infer<typeof MANAGEMENT_ORGS_SCHEMA>;

// user-management
export const getManagementOrgsPromise = async () => {
  const res = await ky.get(`${ENV.FRONTEND_API_URL}/get_all_orgs`).json();

  const parsed = MANAGEMENT_ORGS_SCHEMA.safeParse(res);

  if (!parsed.success) {
    console.error(parsed.error);
    return {
      raw: [],
      asOptions: [],
    };
  }

  return {
    raw: parsed.data,
    asOptions: parsed.data?.map((org) => ({
      label: org.org_name,
      value: org.uuid,
    })),
  };
};
