import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const UPLOAD_ORG_LOGO_SCHEMA = z.object({
  base64: z.string(),
  fileName: z.string(),
  type: z.string(),
});

const UPLOAD_ORG_LOGO_RESPONSE_SCHEMA = z.object({
  data: z.object({
    publicUrl: z.string(),
  }),
  error: z.string().optional().nullable(),
});

export type UploadOrgLogoParam = z.infer<typeof UPLOAD_ORG_LOGO_SCHEMA>;

export const uploadOrgLogoPromise = async (param: UploadOrgLogoParam) => {
  const parsedParam = UPLOAD_ORG_LOGO_SCHEMA.safeParse(param);

  if (!parsedParam.success) {
    console.error(parsedParam.error);
    return null;
  }

  const res = await ky
    .post(`${ENV.FRONTEND_API_URL}/upload_org_logo`, {
      body: JSON.stringify(param),
    })
    .json();

  const parsedRes = UPLOAD_ORG_LOGO_RESPONSE_SCHEMA.safeParse(res);

  if (!parsedRes.success) {
    throw new Error(parsedRes.error.message);
  }

  return parsedRes.data.data.publicUrl;
};
