import { UI_ROUTES } from './routes';

export const LIVE_BLOCK_ROOM_ID_PREFIX_MAP = {
  DEVICE_STATUS: 'visionr:device-status:',
  INSTALL_MANAGEMENT: 'visionr:install-comments:',
} as const;

const LIVE_BLOCK_ROOM_ID_PREFIX_LABEL_MAP = {
  [LIVE_BLOCK_ROOM_ID_PREFIX_MAP.DEVICE_STATUS]: 'Device Status',
  [LIVE_BLOCK_ROOM_ID_PREFIX_MAP.INSTALL_MANAGEMENT]: 'Install Management',
} as const;

const getRoomIdMeta = (roomKey: string, roomId: string) => {
  switch (roomKey) {
    case LIVE_BLOCK_ROOM_ID_PREFIX_MAP.DEVICE_STATUS: {
      const removePrefix = roomId.replace(LIVE_BLOCK_ROOM_ID_PREFIX_MAP.DEVICE_STATUS, '');
      const [orgId, deviceId] = removePrefix.split(':');

      return [orgId, deviceId] as const;
    }

    case LIVE_BLOCK_ROOM_ID_PREFIX_MAP.INSTALL_MANAGEMENT: {
      const removePrefix = roomId.replace(LIVE_BLOCK_ROOM_ID_PREFIX_MAP.INSTALL_MANAGEMENT, '');

      const [installId, orgId] = removePrefix.split(':');

      return [installId, orgId] as const;
    }

    default:
      return [];
  }
};

const getRoomUrl = (roomKey: string, roomId: string) => {
  switch (roomKey) {
    case LIVE_BLOCK_ROOM_ID_PREFIX_MAP.DEVICE_STATUS: {
      const [orgId, deviceId] = getRoomIdMeta(roomKey, roomId);

      return `${UI_ROUTES.DEVICE_STATUS}/${orgId}/${deviceId}`;
    }

    case LIVE_BLOCK_ROOM_ID_PREFIX_MAP.INSTALL_MANAGEMENT: {
      const [installId] = getRoomIdMeta(roomKey, roomId);

      return `${UI_ROUTES.INSTALLS}/${installId}`;
    }

    default:
      return null;
  }
};

const getRoomLabel = (roomKey: string, roomId: string) => {
  switch (roomKey) {
    case LIVE_BLOCK_ROOM_ID_PREFIX_MAP.DEVICE_STATUS: {
      const [orgId] = getRoomIdMeta(roomKey, roomId);

      return `${LIVE_BLOCK_ROOM_ID_PREFIX_LABEL_MAP[roomKey]} for ${orgId}`;
    }

    case LIVE_BLOCK_ROOM_ID_PREFIX_MAP.INSTALL_MANAGEMENT: {
      const [, orgId] = getRoomIdMeta(roomKey, roomId);

      return `${LIVE_BLOCK_ROOM_ID_PREFIX_LABEL_MAP[roomKey]} for ${orgId}`;
    }

    default:
      return null;
  }
};

export const getRoomDetailsByRoomId = (roomId: string) => {
  const roomKey = Object.values(LIVE_BLOCK_ROOM_ID_PREFIX_MAP).find((key) => roomId.startsWith(key));

  if (!roomKey) return null;

  const url = getRoomUrl(roomKey, roomId);

  return {
    name: getRoomLabel(roomKey, roomId) || roomId,
    ...(url ? { url } : {}),
  };
};
