import i18next, { i18n, InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language, Translation } from './languages';
import { i18nResources } from './i18n-resources';
import { i18nConfig } from './config';

export const initI18n = (language?: Translation | Language): i18n => {
  const instance = i18next.createInstance();

  let options: InitOptions = {
    fallbackLng: i18nConfig.fallbackLng,
    interpolation: i18nConfig.interpolationOptions,
  };

  if (!language || typeof language === 'string') {
    options = {
      ...options,
      resources: i18nResources,
      lng: language,
    };
  } else {
    options = {
      ...options,
      resources: { ...i18nResources, [i18nConfig.customLanguage]: { translation: language } },
      lng: i18nConfig.customLanguage,
    };
  }

  instance.use(initReactI18next).init(options);

  return instance;
};

export default initI18n;
