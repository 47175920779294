import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

export const MANAGEMENT_USER_SCHEMA = z.object({
  uuid: z.string(),
  user_name: z.string(),
  user_real_name: z.string(),
  user_email: z.string(),
  user_password: z.string(),
  user_currency: z.string(),
  user_tz: z.string(),
  demo_user: z.boolean(),
  expires_at: z.string().nullable().optional(),
  require_password_change: z.boolean(),
  product_description_fieldname: z.string(),
  value_fieldname: z.string(),
  is_epos_customer: z.boolean(),
  is_entrance_customer: z.boolean(),
  is_internal_area_customer: z.boolean(),
  is_internal_area_epos_customer: z.boolean(),
  is_product_analysis_customer: z.boolean(),
  is_profile_analysis_customer: z.boolean(),
  earliest_open_hour: z.string().nullable(),
  latest_close_hour: z.string().nullable(),
  stores: z.array(
    z.object({
      uuid: z.string(),
      store_name: z.string(),
      override_time: z.string().nullable(),
      devices: z.array(
        z.object({
          uuid: z.string(),
          device_key: z.string(),
        })
      ),
    })
  ),
  orgs: z.array(
    z.object({
      uuid: z.string(),
      org_name: z.string(),
      org_id: z.string(),
      logo_url: z.string().url(),
    })
  ),
  role: z.array(
    z.object({
      user_role: z.string(),
    })
  ),
  should_send_weekly_reports: z.boolean(),
  should_send_monthly_reports: z.boolean(),
  enable_clickhouse_queries: z.boolean(),
});

export type GetManagementUserSchema = z.infer<typeof MANAGEMENT_USER_SCHEMA>;

export type GetManagementUserStore = GetManagementUserSchema['stores'];

export type GetManagementUserOrgs = GetManagementUserSchema['orgs'];

export type GetManagementUserRoles = GetManagementUserSchema['role'];

export const getManagementUserPromise = async (userId: string) => {
  try {
    const response = await ky
      .post(`${ENV.FRONTEND_API_URL}/management_get_user`, {
        body: userId,
      })
      .json();

    const result = MANAGEMENT_USER_SCHEMA.safeParse(response);

    if (!result.success) {
      console.error(result.error);
      return null;
    }

    return {
      ...result.data,
      earliest_open_hour: result.data.earliest_open_hour ?? '',
      latest_close_hour: result.data.latest_close_hour ?? '',
    };
  } catch (e) {
    console.error(e);
    return null;
  }
};
