import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

export const useNotificationOpenStateStore = createWithEqualityFn<{
  open: boolean;
}>(
  () => ({
    open: false,
  }),
  shallow
);
