import * as React from 'react';

import { useOthers, useUpdateMyPresence } from '@liveblocks/react';
import { P16 } from '../Typography';
import { USERS } from '../../../providers/users';

type Mutable<T> = {
  -readonly [K in keyof T]: T[K];
};

interface Cords {
  x: number;
  y: number;
}

interface CursorProps extends Cords {
  color: string;
  id?: string;
}

const COLORS = ['#E57373', '#9575CD', '#4FC3F7', '#81C784', '#FFF176', '#FF8A65', '#F06292', '#7986CB'];

const Cursor = ({ color, x, y, id }: CursorProps) => {
  const useName = USERS.find((user) => user.id === id)?.name || id;

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 99999,
        transform: `translateX(${x}px) translateY(${y}px)`,
        transition: 'all .3s ease-out',
      }}
    >
      <svg width="24" height="36" viewBox="0 0 24 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z"
          fill={color}
        />
      </svg>

      <P16 $margin="-20px 0 0 0" $backgroundColor={color} $padding="0 8px">
        {useName}
      </P16>
    </div>
  );
};

export const LiveCursors = ({ children }: { children: React.ReactNode }) => {
  const updateMyPresence = useUpdateMyPresence();

  const others = useOthers();

  const deduplicatedOthers = (others || {}).reduce(
    (acc, current) => {
      const found = acc.find((item) => item.id === current.id);

      // If `id` already exists and the current `connectionId` is greater, replace it
      if (found) {
        if (current.connectionId > found.connectionId) {
          acc = acc.map((item) => (item.id === current.id ? current : item));
        }
      } else {
        // If `id` does not exist in the accumulator, add it
        acc.push(current);
      }

      return acc;
    },
    [] as Mutable<typeof others>
  );

  return (
    <div
      onPointerMove={(event) => {
        updateMyPresence({
          cursor: {
            x: event.clientX,

            y: event.clientY,
          },
        });
      }}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {children}
      {deduplicatedOthers.map(({ connectionId, id, presence }) => {
        return (
          <Cursor
            key={connectionId}
            x={(presence.cursor as unknown as Cords)?.x}
            y={(presence.cursor as unknown as Cords)?.y}
            color={COLORS[connectionId % COLORS.length]}
            id={id}
          />
        );
      })}
    </div>
  );
};
