import ky from 'ky';
import { ENV } from 'src/utils/env';
import { z } from 'zod';
import { MANAGEMENT_USER_SCHEMA } from '../get-management-user';

export const CREATE_USER_RESULT_SCHEMA = z.object({
  message: z.string(),
});

export const UPDATE_USER_RESULT_SCHEMA = CREATE_USER_RESULT_SCHEMA;

export const CREATE_USER_SCHEMA = MANAGEMENT_USER_SCHEMA.extend({
  stores: z.array(z.string()),
  orgs: z.array(z.string()),
  role: z.array(z.string()),
});

export type CreateUserSchema = z.infer<typeof CREATE_USER_SCHEMA>;

export const createNewUserPromise = async (user: CreateUserSchema) => {
  const validateParam = CREATE_USER_SCHEMA.safeParse(user);

  if (!validateParam.success) {
    console.error(validateParam.error);
    return false;
  }

  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/create_new_user`, {
      body: JSON.stringify(user),
    })
    .json();

  const res = CREATE_USER_RESULT_SCHEMA.safeParse(response);

  if (!res.success) {
    console.error(res.error);
    return false;
  }

  return (res?.data?.message || '')?.toLowerCase()?.includes('created');
};

export const updateUserPromise = async (user: Partial<CreateUserSchema>) => {
  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/update_user`, {
      body: JSON.stringify(user),
    })
    .json();

  const res = UPDATE_USER_RESULT_SCHEMA.safeParse(response);

  if (!res.success) {
    console.error(res.error);
    return false;
  }

  return (res?.data?.message || '')?.toLowerCase()?.includes('updated');
};
