import styled from 'styled-components';
import { hexToRgba } from '../../../utils';

export const StyledLiveBlockNotification = styled.div`
  .lb-root.lb-inbox-notification-list {
    li a {
      background-color: ${(props) => props.theme.colors.appBackground} !important;
      color: ${(props) => props.theme.colors.textColor} !important;
      transition: all 0.3s ease-out;

      &:hover {
        background-color: ${(props) => hexToRgba(props.theme.colors.primary, 0.15)} !important;
      }

      p {
        color: ${(props) => props.theme.colors.textColor} !important;
      }
    }

    .lb-name.lb-user.lb-comment-author {
      color: ${(props) => props.theme.colors.primary} !important;
    }

    .lb-root.lb-inbox-notification-comment.lb-comment {
      border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
      padding-bottom: 8px;
    }

    .lb-inbox-notification-list-item:where(:not(:last-of-type)) {
      border-block-end: 1px solid ${(props) => props.theme.colors.borderColor};
    }
  }
`;
