import * as React from 'react';

export const AuditLogsIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginLeft: '-1px',
      }}
    >
      <path
        d="M8.05943 6.5C8.33557 6.5 8.55943 6.72386 8.55943 7V7.77559C8.55943 8.97016 8.37331 10.1574 8.00776 11.2947L6.67664 15.4359C6.59214 15.6988 6.31052 15.8434 6.04762 15.7589C5.78473 15.6744 5.64011 15.3928 5.72461 15.1299L7.05573 10.9887C7.38949 9.95031 7.55943 8.86628 7.55943 7.77559V7C7.55943 6.72386 7.78329 6.5 8.05943 6.5Z"
        fill="currentColor"
      />
      <path
        d="M6.05944 7C6.05944 5.89543 6.95487 5 8.05944 5C9.16401 5 10.0594 5.89543 10.0594 7C10.0594 7.27614 9.83558 7.5 9.55944 7.5C9.2833 7.5 9.05944 7.27614 9.05944 7C9.05944 6.44772 8.61173 6 8.05944 6C7.50716 6 7.05944 6.44772 7.05944 7V7.33234C7.05944 7.74064 7.0377 8.14812 6.99447 8.55308C6.96515 8.82766 6.7188 9.02649 6.44422 8.99718C6.16963 8.96786 5.9708 8.72151 6.00012 8.44692C6.03959 8.07719 6.05944 7.70514 6.05944 7.33234V7ZM9.5687 8.00017C9.84475 8.00732 10.0627 8.23691 10.0556 8.51296C10.0262 9.64813 9.82874 10.7732 9.4693 11.8516L8.20269 15.6514C8.11537 15.9134 7.83221 16.0549 7.57024 15.9676C7.30827 15.8803 7.16669 15.5971 7.25401 15.3352L8.52062 11.5353C8.8488 10.5508 9.02905 9.5235 9.05592 8.48704C9.06307 8.21099 9.29265 7.99301 9.5687 8.00017ZM6.21335 10.1152C6.47708 10.197 6.62453 10.4772 6.54268 10.7409L5.23974 14.9393C5.1579 15.203 4.87775 15.3505 4.61401 15.2686C4.35028 15.1868 4.20283 14.9066 4.28468 14.6429L5.58762 10.4445C5.66946 10.1808 5.94961 10.0333 6.21335 10.1152Z"
        fill="currentColor"
      />
      <path
        d="M4.75876 5.83338C5.239 4.47466 6.53477 3.5 8.05947 3.5C9.99247 3.5 11.5595 5.067 11.5595 7C11.5595 7.27614 11.3356 7.5 11.0595 7.5C10.7833 7.5 10.5595 7.27614 10.5595 7C10.5595 5.61929 9.44019 4.5 8.05947 4.5C6.97174 4.5 6.04504 5.19496 5.7016 6.16662C5.60958 6.42698 5.32392 6.56344 5.06356 6.47142C4.8032 6.3794 4.66674 6.09373 4.75876 5.83338ZM5.05936 7.5024C5.33417 7.52942 5.53505 7.77411 5.50803 8.04892C5.44042 8.73661 5.3064 9.41698 5.10737 10.0804L3.88578 14.1524C3.80643 14.4169 3.52769 14.567 3.26319 14.4876C2.9987 14.4083 2.8486 14.1295 2.92795 13.865L4.14954 9.79307C4.33 9.19152 4.45153 8.57462 4.51283 7.95108C4.53985 7.67626 4.78454 7.47538 5.05936 7.5024ZM11.0595 8.15C11.3356 8.15 11.5595 8.37386 11.5595 8.65C11.5595 9.9304 11.3456 11.2018 10.9268 12.4118L9.83791 15.5575C9.74758 15.8184 9.46281 15.9568 9.20186 15.8664C8.94091 15.7761 8.8026 15.4913 8.89293 15.2304L9.98183 12.0847C10.3642 10.9799 10.5595 9.81907 10.5595 8.65C10.5595 8.37386 10.7833 8.15 11.0595 8.15Z"
        fill="currentColor"
      />
      <path
        d="M3.9017 4.22187C4.79781 2.88315 6.32539 2 8.05943 2C8.41717 2 8.76668 2.03765 9.10401 2.10938C9.37411 2.16682 9.54651 2.43235 9.48907 2.70245C9.43163 2.97255 9.16611 3.14495 8.89601 3.08751C8.62664 3.03023 8.34684 3 8.05943 3C6.67305 3 5.45112 3.70488 4.73271 4.77813C4.5791 5.00761 4.26855 5.06911 4.03907 4.9155C3.8096 4.7619 3.74809 4.45135 3.9017 4.22187ZM10.6217 3.26669C10.8022 3.05774 11.1179 3.03471 11.3269 3.21524C12.3872 4.13135 13.0594 5.48745 13.0594 7V8.5C13.0594 8.77614 12.8356 9 12.5594 9C12.2833 9 12.0594 8.77614 12.0594 8.5V7C12.0594 5.79015 11.5229 4.70617 10.6731 3.97192C10.4642 3.79139 10.4411 3.47564 10.6217 3.26669ZM3.68095 5.84243C3.95413 5.88276 4.14289 6.13692 4.10256 6.4101C4.07417 6.60234 4.05943 6.7993 4.05943 7C4.05943 7.7105 3.95889 8.41742 3.76079 9.09975L2.62184 13.0228C2.54485 13.288 2.26746 13.4406 2.00226 13.3636C1.73707 13.2866 1.5845 13.0092 1.66149 12.744L2.80045 8.82094C2.97224 8.22922 3.05943 7.61616 3.05943 7C3.05943 6.75025 3.07778 6.50448 3.11328 6.26403C3.15362 5.99085 3.40777 5.80209 3.68095 5.84243ZM12.5627 9.50175C12.8379 9.52484 13.0423 9.76663 13.0192 10.0418C12.9352 11.0421 12.7206 12.0285 12.3796 12.9756L11.6348 15.0445C11.5413 15.3043 11.2548 15.4391 10.995 15.3456C10.7352 15.252 10.6004 14.9656 10.6939 14.7058L11.4387 12.6369C11.75 11.7721 11.946 10.8715 12.0227 9.9582C12.0458 9.68302 12.2875 9.47866 12.5627 9.50175Z"
        fill="currentColor"
      />
      <path
        d="M4.80909 1.36988C5.76566 0.816541 6.87635 0.5 8.05945 0.5C11.6493 0.5 14.5594 3.41015 14.5594 7C14.5594 7.27614 14.3356 7.5 14.0594 7.5C13.7833 7.5 13.5594 7.27614 13.5594 7C13.5594 3.96243 11.097 1.5 8.05945 1.5C7.05683 1.5 6.11828 1.76782 5.30982 2.23549C5.07079 2.37376 4.76492 2.29208 4.62665 2.05305C4.48838 1.81402 4.57006 1.50816 4.80909 1.36988ZM3.92069 2.62743C4.12645 2.8116 4.14396 3.12769 3.9598 3.33346C3.0886 4.30684 2.55945 5.59103 2.55945 7C2.55945 7.27614 2.33559 7.5 2.05945 7.5C1.7833 7.5 1.55945 7.27614 1.55945 7C1.55945 5.33555 2.18571 3.81618 3.21466 2.66654C3.39883 2.46078 3.71493 2.44327 3.92069 2.62743ZM1.91357 8.01867C2.1794 8.09344 2.33429 8.36954 2.25952 8.63537L1.48133 11.4023C1.40657 11.6681 1.13046 11.823 0.864633 11.7482C0.598804 11.6735 0.443916 11.3974 0.51868 11.1315L1.29687 8.36463C1.37163 8.0988 1.64774 7.94391 1.91357 8.01867ZM14.0635 8.5001C14.3396 8.5055 14.559 8.7337 14.5536 9.00979C14.5242 10.5089 14.3929 12.0347 13.8272 13.5432L13.7573 13.7297C13.6604 13.9882 13.3722 14.1192 13.1136 14.0223C12.855 13.9253 12.724 13.6371 12.821 13.3785L12.8909 13.1921C13.397 11.8425 13.5252 10.4511 13.5538 8.99021C13.5592 8.71412 13.7874 8.49469 14.0635 8.5001Z"
        fill="currentColor"
      />
    </svg>
  );
};
