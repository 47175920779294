import { z } from 'zod';
import { ENV } from 'src/utils/env';
import ky from 'ky';
import { RESINGESTOR_BASE_SCHEMA } from '../_common/base-schema';
import { TASK_ACTION_MAP } from '../../list-tasks/listTasksPromise';

const DELETE_AND_REINGEST_SCHEMA = RESINGESTOR_BASE_SCHEMA.pick({
  index1: true,
  earliest1: true,
  latest1: true,
  source1: true,
  sourcetype1: true,
});

export type DeleteSchema = z.infer<typeof DELETE_AND_REINGEST_SCHEMA>;

const DELETE_AND_REINGEST_RESPONSE_SCHEMA = z.object({
  success: z.boolean(),
  task_id: z.string(),
});

export const reingestorDeletePromise = async (param: DeleteSchema) => {
  const validatedParam = DELETE_AND_REINGEST_SCHEMA.safeParse(param);

  if (!validatedParam.success) {
    throw new Error('Invalid param');
  }
  const response = await ky.post(`${ENV.FRONTEND_API_URL}/reingestor`, {
    body: JSON.stringify({
      params: {
        ...validatedParam.data,
        action_type: TASK_ACTION_MAP.delete,
      },
    }),
  });
  if (!response.ok) {
    return false;
  }
  const responseData = await response.json();
  const validatedResponse = DELETE_AND_REINGEST_RESPONSE_SCHEMA.safeParse(responseData);
  if (!validatedResponse.success) {
    console.error(validatedResponse.error);
    return false;
  }

  return validatedResponse.data?.success;
};
