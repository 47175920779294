import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const VIEW_AS_USER_SCHEMA = z.object({
  requested_user: z.string(),
});

export type ViewAsUserParams = z.infer<typeof VIEW_AS_USER_SCHEMA>;

const VIEW_AS_USER_RESPONSE_SCHEMA = z.object({
  data: z.object({ message: z.string() }),
  error: z.string().optional().nullable(),
});

export const viewAsUserPromise = async (params: ViewAsUserParams) => {
  const validateParams = VIEW_AS_USER_SCHEMA.safeParse(params);

  if (!validateParams.success) {
    console.error(validateParams.error);
  }

  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/view_as_user`, {
      body: JSON.stringify(params),
    })
    .json();

  const res = VIEW_AS_USER_RESPONSE_SCHEMA.safeParse(response);

  if (!res.success) {
    return null;
  }

  return res.data?.data.message;
};
