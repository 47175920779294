import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalStore } from 'src/hooks';
import { MenuToggleIcon } from 'src/assets/icons';
import { AppBar, StyledAppBarTitleWrapper, StyledIconButton } from './PageHeader.styled';
import { H1 } from '../Typography';
import { NotificationButton } from '../LiveBlockNotification/NotificationButton';

export interface PageHeaderProps {
  children?: React.ReactNode;
  customTitle?: React.ReactNode;
  title: string;
}

export const PageHeader: React.FunctionComponent<PageHeaderProps> = ({ title, children, customTitle }) => {
  const { t } = useTranslation();
  const prefersWideDashboardMenu = useGlobalStore((state) => state.prefersWideDashboardMenu);
  const setPrefersWideDashboardMenu = useGlobalStore((state) => state.setPrefersWideDashboardMenu);

  const toggleMobileNav = () => {
    setPrefersWideDashboardMenu(!prefersWideDashboardMenu);
  };

  return (
    <AppBar $prefersWideDashboardMenu={prefersWideDashboardMenu}>
      <StyledAppBarTitleWrapper>
        <StyledIconButton $mobileNavOpen={prefersWideDashboardMenu} onClick={toggleMobileNav}>
          <MenuToggleIcon />
        </StyledIconButton>
        {customTitle || (
          <H1 $fontWeight={500} $fontSize="22px">
            {t(title)}
          </H1>
        )}
        &nbsp;&nbsp;
        <NotificationButton />
      </StyledAppBarTitleWrapper>
      <div className="header-children">{children}</div>
    </AppBar>
  );
};

PageHeader.defaultProps = {
  children: null,
  customTitle: null,
};
