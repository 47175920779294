import * as React from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from 'styled-components';

import { CloseIcon } from 'src/assets/icons';
import { COLORS } from 'src/constants';
import { P16 } from '../Typography';
import { StyledWrapper, StyledSnackbarContent, StyledSnackbarMessage, StyledSnackbarIcon } from './Snackbar.styled';
import { SNACKBAR_COLORS, SNACKBAR_BG_COLORS_LIGHT, SNACKBAR_BG_COLORS_DARK } from './constant';

export type SnackbarProps = CustomContentProps;

export const Snackbar = React.forwardRef<HTMLDivElement, SnackbarProps>(({ id, message, variant }, ref) => {
  const isDarkMode = useTheme().isDarkTheme;
  const { closeSnackbar } = useSnackbar();
  const color = SNACKBAR_COLORS[variant];
  const bgColor = isDarkMode ? SNACKBAR_BG_COLORS_DARK[variant] : SNACKBAR_BG_COLORS_LIGHT[variant];
  const { t } = useTranslation();

  const handleDismiss = React.useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <StyledWrapper $color={color} $bgColor={bgColor} data-cy="snackbar">
        <StyledSnackbarContent>
          <StyledSnackbarIcon $color={color}>&nbsp;</StyledSnackbarIcon>
          <StyledSnackbarMessage>
            <P16 $fontWeight={500} $capitalize>
              {variant}
            </P16>

            <P16>{!isEmpty(message) ? t(message as string) : null}</P16>
          </StyledSnackbarMessage>
        </StyledSnackbarContent>

        <div>
          <IconButton size="small" onClick={handleDismiss} aria-label={t('aria.close.button')}>
            <CloseIcon fill={COLORS.grey1} />
          </IconButton>
        </div>
      </StyledWrapper>
    </SnackbarContent>
  );
});

Snackbar.displayName = 'Snackbar';
