import * as React from 'react';
import { styled } from '@mui/material/styles';
import { BellIcon } from 'src/assets/icons/BellIcon';
import { useUnreadInboxNotificationsCount } from '@liveblocks/react/suspense';
import Badge, { badgeClasses } from '@mui/material/Badge';
import { useNotification } from 'src/hooks';
import { IconButton } from '../IconButton';
import { useNotificationOpenStateStore } from './notification-store';

const NotificationBadge = styled(Badge)`
  & .${badgeClasses.badge} {
    top: -12px;
    right: -6px;
  }
`;

export const NotificationButton = () => {
  const { enqueueInfoNotification } = useNotification();
  const { count } = useUnreadInboxNotificationsCount();

  const onceRef = React.useRef(false);
  React.useEffect(() => {
    if (!onceRef.current && count > 0) {
      enqueueInfoNotification(`You have ${count} unread notifications`);
      onceRef.current = true;
    }
  }, [count, enqueueInfoNotification]);

  const handleClick = () => {
    useNotificationOpenStateStore.setState({
      open: true,
    });
  };

  return (
    <IconButton onClick={handleClick}>
      <BellIcon />
      {count > 0 ? <NotificationBadge badgeContent={count} color="error" overlap="circular" /> : null}
    </IconButton>
  );
};
