import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const GET_VIDEO_URL_SCHEMA = z.object({
  url: z.string().url(),
});

export const getVideoUrlPromise = async (taskId: string) => {
  const res = await ky
    .post(`${ENV.FRONTEND_API_URL}/get_video_url`, {
      body: taskId,
    })
    .json();

  const result = GET_VIDEO_URL_SCHEMA.safeParse(res);

  if (!result.success) {
    console.error(result.error);
    return undefined;
  }

  const url = result.data?.url;

  return url;
};
