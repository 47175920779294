import { z } from 'zod';
import ky from 'ky';
import { ENV } from 'src/utils/env';

const RETRY_USER_REPORT_SCHEMA = z.object({
  user_emails: z.array(z.string().email()),
  reportType: z.enum(['weekly', 'monthly']),
});

const RETRY_USER_REPORT_RESPONSE_SCHEMA = z.object({
  data: z.object({
    success: z.boolean(),
    message: z.string().nullable().optional(),
  }),
});

export const USER_REPORT_RANGE_TYPE = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
} as const;

export type UserReportRangeType = (typeof USER_REPORT_RANGE_TYPE)[keyof typeof USER_REPORT_RANGE_TYPE];

export type RetryUserReportParams = z.infer<typeof RETRY_USER_REPORT_SCHEMA>;

export const retryUserReportPromise = async (params: RetryUserReportParams) => {
  const validateParams = RETRY_USER_REPORT_SCHEMA.safeParse(params);

  if (!validateParams.success) {
    console.error('retryUserReportPromise', validateParams.error);

    return false;
  }

  const response = await ky.post(`${ENV.FRONTEND_API_URL}/retry_user_report`, {
    body: JSON.stringify(params),
  });

  const body = await response.json();

  const validateResponse = RETRY_USER_REPORT_RESPONSE_SCHEMA.safeParse(body);

  if (!validateResponse.success) {
    console.error('retryUserReportPromise', validateResponse.error, body);

    return false;
  }

  return validateResponse.data.data.success;
};
