export const USERS = [
  {
    name: 'MaryDawn Joven',
    email: 'marydawn.joven@visionr.com',
  },
  {
    name: "Shane O'Sullivan",
    email: 'shane.osullivan@visionr.com',
    avatar: 'https://visionr.com/img/leadership/shane.webp',
  },
  {
    name: 'Oran Mulvey',
    email: 'oran.mulvey@visionr.com',
    avatar: 'https://visionr.com/img/leadership/oran.webp',
  },
  {
    name: 'Elizabeth-Anne',
    email: 'elizabethann@visionr.com',
    avatar: 'https://visionr.com/img/leadership/elizabeth.webp',
  },
  {
    name: 'Mark Dunne',
    email: 'mark.dunne@visionr.com',
    avatar: 'https://visionr.com/img/leadership/mark.webp',
  },
  {
    name: 'Robert Lucey',
    email: 'robert.lucey@visionr.com',
    avatar: 'https://visionr.com/img/leadership/rob.webp',
  },
  {
    name: 'Farouq Ayofe',
    email: 'farouq.ayofe@visionr.com',
    avatar: 'https://visionr.com/img/leadership/farouq.webp',
  },
].map((user) => ({
  id: user.email,
  ...user,
}));
