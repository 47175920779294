/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import { Command } from 'cmdk';
import { useTranslation } from 'react-i18next';
import { SYSTEMS_SHORTCUTS_ID_MAP, SYSTEMS_SHORTCUTS_LABEL_MAP, SYSTEMS_SHORTCUTS_MAP } from './systems-shortcuts';

export interface CommandMenuSystemsGroupProps {
  onSelect: (value: string) => void;
}

export const CommandMenuSystemsGroup: React.FunctionComponent<CommandMenuSystemsGroupProps> = ({ onSelect }) => {
  const { t } = useTranslation();

  return (
    <Command.Group heading={t('uiLabel.systems')}>
      <Command.Item onSelect={onSelect} value={t(SYSTEMS_SHORTCUTS_LABEL_MAP[SYSTEMS_SHORTCUTS_ID_MAP.TOGGLE_THEME])}>
        {t(SYSTEMS_SHORTCUTS_LABEL_MAP[SYSTEMS_SHORTCUTS_ID_MAP.TOGGLE_THEME])}

        <div cmdk-shortcuts="">
          <kbd>⌘</kbd>
          <kbd>⇧</kbd>

          {SYSTEMS_SHORTCUTS_MAP[SYSTEMS_SHORTCUTS_ID_MAP.TOGGLE_THEME].split(' ').map((key) => {
            return <kbd key={key}>{key}</kbd>;
          })}
        </div>
      </Command.Item>
    </Command.Group>
  );
};
