const recursivelyDecodeUriComponentWithMaxRecurse = (str: string) => {
  let result = decodeURIComponent(str);

  let recurseCount = 0;

  /**
   * Note:
   * some strings requires multiple decodeURIComponent calls to fully decode
   * we also don't want to get stuck in an infinite loop, so max recursion count is 3
   */
  while (/%[0-9a-f]{2}/gi.test(result) && recurseCount < 3) {
    result = decodeURIComponent(result);
    recurseCount++;
  }

  /**
   * Note:
   * if we still have decodeable strings, then we've reached the max recursion count, so log an error
   */
  if (/%[0-9a-f]{2}/gi.test(result)) {
    console.error('recursivelyDecodeUriComponentWithMaxRecurse: max recursion count reached', {
      cause: {
        result,
        recurseCount,
        value: str,
        traceId: 'recursivelyDecodeUriComponent',
      },
    });
  }

  return result;
};

export const recursivelyDecodeUriComponent = (str: string) => {
  try {
    return recursivelyDecodeUriComponentWithMaxRecurse(str);
  } catch (e) {
    console.error('recursivelyDecodeUriComponentWithMaxRecurse', {
      cause: {
        error: e as Error,
        traceId: 'recursivelyDecodeUriComponent',
        value: str,
      },
    });

    return str;
  }
};
