import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const GET_DEVICE_DETAILS_SCHEMA = z.object({
  data: z.object({
    uuid: z.string(),
    display_name: z.string(),
    created_time: z.string(),
    store_code: z.string(),
    location_id: z.string().optional().nullable(),
    organisations: z.string().array(),
    is_live: z.boolean(),
    scrubber_config: z.object({
      uuid: z.string(),
      min_female_confidence: z.number(),
      min_male_confidence: z.number(),
      inverse_age_bleed_rate: z.number(),
      inverse_age_bleed_middle_age: z.number(),
      gender_body_conf: z.number(),
      object_track_score_thresh: z.number(),
    }),
    maintenance_tasks: z
      .object({
        uuid: z.string(),
        wifi_details_update: z.boolean(),
      })
      .nullable(),
  }),
  error: z.string().optional().nullable(),
});

export type GetDeviceDetailsResponse = z.infer<typeof GET_DEVICE_DETAILS_SCHEMA>;

export const getDeviceDetailsPromise = async (deviceId: string) => {
  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/get_device_details`, {
      body: deviceId,
    })
    .json();
  const res = GET_DEVICE_DETAILS_SCHEMA.safeParse(response);

  if (!res.success) {
    console.log('🚀 ~ getDeviceDetailsPromise ~ res.error.message:', res.error.message);
    throw new Error(res.error.message);
  }

  return res.data.data;
};
