import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { setUser } from '@sentry/react';
import { useGlobalStore } from 'src/hooks';
import { ROUTES } from 'src/constants';
import { VisionrEmblem, MenuToggleIcon } from 'src/assets/icons';
import { useIsLoggedIn, useGetUserDetails } from 'src/hooks/api';
import { RoomProvider } from '@liveblocks/react/suspense';
import {
  StyledDrawerWrapper,
  StyledDrawer,
  StyledMain,
  StyledDrawerHeader,
  StyledLogo,
  StyledCommandShortcut,
} from './Layout.styled';
import { IconButton, AuthenticatingState, UnAuthenticatedState } from '../atoms';
import { Links } from './Links';
import { useMenuToggle } from './hooks/useMenuToggle';
import { NavBarActions } from './NavBarActions';
import { ErrorBoundary } from '../ErrorBoundary';
import { CommandMenu } from './CommandMenu';
import { LiveCursors } from '../atoms/LiveBlockPresence/LiveBlockPresence';

export const Layout: React.FunctionComponent = () => {
  const { toggleMenu, menuIsOpen, handleMouseOver, handleMouseExist, drawerType, handleCloseMobileMenu } =
    useMenuToggle();
  const { userDetails } = useGetUserDetails();

  const location = useLocation();

  const prefersWideDashboardMenu = useGlobalStore((state) => state.prefersWideDashboardMenu);
  const { isLoggedIn, isAuthenticating } = useIsLoggedIn();

  React.useEffect(() => {
    if (!userDetails?.email) return;

    setUser({
      username: userDetails?.email || userDetails?.username,
    });
  }, [userDetails?.email, userDetails?.username]);

  if (isAuthenticating) {
    return <AuthenticatingState />;
  }

  if (!isLoggedIn) {
    return <UnAuthenticatedState />;
  }

  return (
    <RoomProvider
      id={`visionr:${location.pathname}`}
      initialPresence={{
        cursor: null,
      }}
    >
      <StyledDrawerWrapper>
        <StyledDrawer
          variant={drawerType}
          open={menuIsOpen}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseExist}
          $prefersWideDashboardMenu={prefersWideDashboardMenu}
          onClose={toggleMenu}
        >
          {/* Dashboard Header */}
          <StyledDrawerHeader $prefersWideDashboardMenu={prefersWideDashboardMenu} $menuIsOpen={menuIsOpen}>
            {/* Org details */}
            <StyledLogo $menuIsOpen={menuIsOpen}>
              <VisionrEmblem />
            </StyledLogo>

            <IconButton onClick={toggleMenu} aria-label="toggle drawer">
              <MenuToggleIcon />
            </IconButton>
          </StyledDrawerHeader>

          {/* Dashboard links */}
          <Links menuIsOpen={menuIsOpen} dashboardLinks={ROUTES} handleCloseMobileMenu={handleCloseMobileMenu} />

          <StyledCommandShortcut>
            <kbd>⌘</kbd>
            <kbd>k</kbd>
          </StyledCommandShortcut>

          {/* NavBarActions - Profile, support buttons etc */}
          <NavBarActions menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
        </StyledDrawer>

        {/* Dashboard main content */}
        <ErrorBoundary componentId="DashboardLayout-StyledMain">
          <StyledMain $prefersWideDashboardMenu={prefersWideDashboardMenu}>
            <LiveCursors>
              <Outlet />
            </LiveCursors>
          </StyledMain>
        </ErrorBoundary>

        <CommandMenu />
      </StyledDrawerWrapper>
    </RoomProvider>
  );
};
