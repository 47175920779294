import ky from 'ky';
import { z } from 'zod';

import { ENV } from 'src/utils/env';

export const EPOS_UPLOAD_SIGN_IN_PARAM_SCHEMA = z.object({
  username: z.string(),
  password: z.string(),
});

export type EposUploadSignInParam = z.infer<typeof EPOS_UPLOAD_SIGN_IN_PARAM_SCHEMA>;

export const eposUploadSignInPromise = async () => {
  try {
    const res = await ky.get(`${ENV.FRONTEND_API_URL}/epos_login`);

    const body: {
      token: string;
    } = await res.json();

    if (!body.token) {
      return {
        token: null,
      };
    }

    return {
      token: body.token,
    };
  } catch {
    return {
      token: null,
    };
  }
};

export type EposUploadSignInResponse = Awaited<ReturnType<typeof eposUploadSignInPromise>>;
