import styled from 'styled-components';

export const PageContentWrapper = styled.div<{
  $padding?: string;
}>`
  padding: ${({ $padding }) => $padding || '20px'};
  min-height: ${(props) => `calc(100vh - ${props.theme.width.appContentOffset})`};
  margin-top: ${(props) => props.theme.width.appContentOffset};
  position: relative;
  color: ${(props) => props.theme.colors.textColor};
  background-color: ${(props) => props.theme.colors.pageContentColor};
`;
